var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout"},[_c('header',[_vm._m(0),_c('ul',[_c('router-link',{attrs:{"to":"/"}},[_c('li',{class:{ 'active-li': _vm.$route.path === '/' }},[_vm._v(" 首页 ")])]),_c('el-dropdown',[_c('li',{class:{
                        'active-li':
                            _vm.$route.path === '/bzt' ||
                            _vm.$route.path === '/bzq' ||
                            _vm.$route.path === '/bzj'
                    },staticStyle:{"font-size":"18px"}},[_vm._v(" 产品与解决方案 ")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{class:{
                            'active-nav-item':
                                _vm.$route.path === '/bzt'
                        }},[_c('router-link',{attrs:{"to":"/bzt"}},[_vm._v(" APT全息知识图谱分析系统（白泽图） ")])],1),_c('el-dropdown-item',{class:{
                            'active-nav-item':
                                _vm.$route.path === '/bzq'
                        }},[_c('router-link',{attrs:{"to":"/bzq"}},[_vm._v(" APT攻击流量检测系统（白泽旗） ")])],1),_c('el-dropdown-item',{class:{
                            'active-nav-item':
                                _vm.$route.path === '/bzj'
                        }},[_c('router-link',{attrs:{"to":"/bzj"}},[_vm._v(" APT线索关联溯源系统（白泽剑） ")])],1)],1)],1),_c('router-link',{attrs:{"to":"/service"}},[_c('li',{class:{ 'active-li': _vm.$route.path === '/service' }},[_vm._v(" 高端安全服务 ")])]),_c('router-link',{attrs:{"to":"/lab"}},[_c('li',{class:{ 'active-li': _vm.$route.path === '/lab' }},[_vm._v(" 白泽安全实验室 ")])]),_c('router-link',{attrs:{"to":"/about"}},[_c('li',{class:{ 'active-li': _vm.$route.path === '/about' }},[_vm._v(" 关于我们 ")])])],1)]),_c('main',[_c('router-view')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})])
}]

export { render, staticRenderFns }