<!--
* 描述该文件作用
* @Author: xuezhuang
* @Date: 2023-MM-DD
-->
<template>
    <div class="layout">
        <header>
            <div>
                <img src="../assets/logo.png" alt="" />
            </div>
            <ul>
                <router-link to="/"
                    ><li :class="{ 'active-li': $route.path === '/' }">
                        首页
                    </li></router-link
                >
                <el-dropdown>
                    <li
                        style="font-size: 18px"
                        :class="{
                            'active-li':
                                $route.path === '/bzt' ||
                                $route.path === '/bzq' ||
                                $route.path === '/bzj'
                        }"
                    >
                        产品与解决方案
                    </li>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            :class="{
                                'active-nav-item':
                                    $route.path === '/bzt'
                            }"
                        >
                            <!-- <div> -->
                            <router-link to="/bzt">
                                APT全息知识图谱分析系统（白泽图）
                            </router-link>

                            <!-- </div> -->
                        </el-dropdown-item>
                        <el-dropdown-item
                            :class="{
                                'active-nav-item':
                                    $route.path === '/bzq'
                            }"
                        >
                            <router-link to="/bzq">
                                APT攻击流量检测系统（白泽旗）
                            </router-link>
                        </el-dropdown-item>
                        <el-dropdown-item
                            :class="{
                                'active-nav-item':
                                    $route.path === '/bzj'
                            }"
                        >
                            <router-link to="/bzj">
                                APT线索关联溯源系统（白泽剑）
                            </router-link>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <!-- </router-link> -->
                <router-link to="/service"
                    ><li :class="{ 'active-li': $route.path === '/service' }">
                        高端安全服务
                    </li></router-link
                >
                <router-link to="/lab"
                    ><li :class="{ 'active-li': $route.path === '/lab' }">
                        白泽安全实验室
                    </li></router-link
                >
                <router-link to="/about"
                    ><li :class="{ 'active-li': $route.path === '/about' }">
                        关于我们
                    </li></router-link
                >
            </ul>
        </header>
        <main>
            <router-view />
        </main>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    mounted() {
        // console.log(this.$route)
    },
    methods: {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1366px) {
    .layout {
        header {
            height: 80px !important;
            img{
                width: 160px;
            }
            ul {
                li {
                    font-size: 14px !important;
                }
            }
        }
    }
}

.active-li {
    border-bottom: 2px solid #55e7ff;
    opacity: 1 !important;
    color: #55e7ff !important;
}
.active-nav-item {
    a {
        color: #55e7ff !important ;
    }
}
::v-deep .el-dropdown-menu__item:hover{
    a{
        color: #55e7ff !important;
    }

}
.layout {
    height: 100%;
    position: relative;
    header {
        z-index: 99;
        box-sizing: border-box;
        position: fixed;
        top: 0;
        height: 100px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        padding: 0 74px;
        justify-content: space-between;

        ul {
            display: flex;
            align-items: center;
            li {
                height: 50px;
                line-height: 50px;
                color: #fff;
                opacity: 0.6;
                font-size: 18px;
                margin: 0 10px;
                padding: 0 30px;
                box-sizing: border-box;
                cursor: pointer;
                &:hover {
                    border-bottom: 2px solid #55e7ff;
                    opacity: 1;
                    color: #55e7ff;
                }
            }
        }
    }
    main {
        height: 100%;
    }
    ::v-deep .el-dropdown-menu__item:hover {
        a {
            color: #55e7ff !important;
        }
    }
}
</style>
