import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import './style.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.use(ElementUI);
import 'animate.css';

// 滚动动画 wow.js
import {
    WOW
} from 'wowjs'
Vue.prototype.$wow = new WOW({
    boxClass: 'wow', // default
    animateClass: 'animated', // default
    offset: 0, // default
    mobile: true, // default
    live: false,

    // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

    callback: function (box) {
        // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
    }
});
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
