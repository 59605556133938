
<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide bgc1">
        <div class="intro">
          <img src="../../assets/logo.png" alt="" />
          <p>知识驱动安全———</p>
          <p
            class="ani color"
            swiper-animate-effect="fadeInUp"
            swiper-animate-duration="1s"
          >
            专注于高级持续威胁防御的网络安全公司
          </p>
          <div
            class="btn ani"
            swiper-animate-effect="fadeInUp"
            swiper-animate-duration="1s"
            @click="goProduct"
          >
            相关产品 >>
          </div>
        </div>
        <div class="mouse-box" style="opacity: 0.4">
          <div class="mouse">
            <img src="../../assets/mouse.png" alt="" />
            <img src="../../assets/mouse-down.png" alt="" />
          </div>
        </div>
      </div>
      <div class="swiper-slide bgc2">
        <div class="intro">
          <h2>业务定位</h2>
          <img src="../../assets/home-bgc-2-line.png" alt="" />
          <p
            class="ani"
            swiper-animate-effect="fadeInUp"
            swiper-animate-duration="1s"
          >
            立足网空对抗视角，着眼网络攻防实战效能，体系化、全链条研究APT防御技术和解决方案，协助政企用户构建可信赖的网络安全防御生态，让国家网络更安全可靠。
          </p>
          <div
            class="btn ani"
            swiper-animate-effect="fadeInUp"
            swiper-animate-duration="1s"
            @click="goServe"
          >
            相关服务 >>
          </div>
        </div>
      </div>
      <div class="swiper-slide bgc3">
        <div class="header">
          <h2>核心产品</h2>
          <span class="line"></span>
        </div>
        <div
          class="intro ani"
          swiper-animate-effect="fadeInUp"
          swiper-animate-duration="1s"
        >
          <div class="intro-one" @click="$router.push('/bzt')">
            <p class="tit">APT全息知识图谱分析系统<span></span></p>
            <p class="info">
              整合多源异构威胁情报，图谱化建模和运营的高级持续威胁知识推理、挖掘、分析系统。
            </p>
          </div>
          <div class="intro-one" @click="$router.push('/bzq')">
            <p class="tit">APT攻击流量检测系统<span></span></p>
            <p class="info">
              集网络威胁行为发现、威胁主体识别、全流量取证溯源于一体的旁路型网络流量威胁检测系统。
            </p>
          </div>
          <div class="intro-one" @click="$router.push('/bzj')">
            <p class="tit">APT线索关联溯源系统<span></span></p>
            <p class="info">
              针对高级持续威胁线索的一站式、智能化关联拓展与归因溯源系统。
            </p>
          </div>
        </div>
      </div>
      <div class="swiper-slide bgc4">
        <div class="header">
          <h2>技术特色创新</h2>
          <span class="line"></span>
        </div>
        <div style="display: flex" >
          <div class="left">
            <img src="../../assets/home-4-icon.gif" alt="gif动图" />
          </div>
          <div class="right intro ani"
          swiper-animate-effect="fadeInUp"
          swiper-animate-duration="1s">
            <div class="intro-one one"><p>APT全息知识图谱</p></div>
            <div class="intro-one two"><p>全链条闭环高端安全服务</p></div>
            <div class="intro-one three"><p>威胁步态（行为）模型检测</p></div>
            <div class="intro-one four"><p>MICC&IGR情报关联模型与智能归因算法</p></div>
          </div>
        </div>
        <!-- <div>
                    <ul
                        class="intro ani"
                        swiper-animate-effect="fadeInUp"
                        swiper-animate-duration="1s"
                    >
                        <li>
                            <div class="img">
                                <img
                                    src="../../assets/home-4-icon-1.png"
                                    alt=""
                                />
                            </div>
                            <p>威胁步态（行为）模型检测</p>
                        </li>
                        <li>
                            <div class="img">
                                <img
                                    src="../../assets/home-4-icon-2.png"
                                    alt=""
                                />
                            </div>
                            <p>APT全息知识图谱</p>
                        </li>
                        <li>
                            <div class="img">
                                <img
                                    src="../../assets/home-4-icon-3.png"
                                    alt=""
                                />
                            </div>
                            <p>全链条闭环高端安全服务</p>
                        </li>
                        <li>
                            <div class="img">
                                <img
                                    src="../../assets/home-4-icon-4.png"
                                    alt=""
                                />
                            </div>
                            <p>MICC&IGR情报关联模型与智能归因算法</p>
                        </li>
                    </ul>
                </div> -->
      </div>
      <div class="swiper-slide bgc5">
        <div class="header">
          <h2>合作客户</h2>
          <span class="line"></span>
        </div>
        <div
          class="carousel ani"
          swiper-animate-effect="fadeInUp"
          swiper-animate-duration="1s"
        >
          <el-carousel
            trigger="click"
            height="330px"
            indicator-position="none"
            :interval="3000"
          >
            <el-carousel-item v-for="item in 4" :key="item">
              <div>
                <img src="../../assets/home-custom-1.png" alt="" />
              </div>
              <div>
                <img src="../../assets/home-custom-2.png" alt="" />
              </div>
              <div>
                <img src="../../assets/home-custom-3.png" alt="" />
              </div>
              <div>
                <img src="../../assets/home-custom-4.png" alt="" />
              </div>
              <div>
                <img src="../../assets/home-custom-5.png" alt="" />
              </div>
              <div>
                <img src="../../assets/home-custom-6.png" alt="" />
              </div>
              <div>
                <img src="../../assets/home-custom-7.png" alt="" />
              </div>
              <div>
                <img src="../../assets/home-custom-8.png" alt="" />
              </div>
              <div>
                <img src="../../assets/home-custom-9.png" alt="" />
              </div>
              <div>
                <img src="../../assets/home-custom-10.png" alt="" />
              </div>
              <div>
                <img src="../../assets/home-custom-11.png" alt="" />
              </div>
              <div>
                <img src="../../assets/home-custom-12.png" alt="" />
              </div> </el-carousel-item
          ></el-carousel>
        </div>
      </div>
      <div class="swiper-slide bgc6">
        <Footer></Footer>
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
//
import * as swiperAni from "@/assets/swiper.animate1.0.3.min.js";
import "@/assets/animate.min.css";

import Footer from "@/components/footer.vue";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";

import Swiper from "swiper";
export default {
  name: "",
  components: { Footer },
  data() {
    return {
      mySwiper: null,
      initialSlide: 0,
      slideHeight: 0,
      footerHeight: 0,
      allowSlideNext: true,
      dir: "down",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$wow.init();
    });
    const footer = document.getElementsByTagName("footer")[0];
    this.footerHeight = footer.offsetHeight;
    this.slideHeight = document.body.clientHeight;
    window.addEventListener("mousewheel", (e) => {
      // console.log(e.wheelDelta)
      if (e.wheelDelta > 0) {
        this.dir = "up";
      } else {
        this.dir = "down";
      }
    });
    this.mySwiper = new Swiper(".swiper-container", {
      direction: "vertical", // 垂直切换选项
      speed: 500,
      mousewheel: true, //滚轮
      allowSlideNext: this.allowSlideNext,
      initialSlide: this.initialSlide, //默认
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true, // 分页器可以点击
      },
      on: {
        init: function () {
          swiperAni.swiperAnimateCache(this); //隐藏动画元素
          swiperAni.swiperAnimate(this); //初始化完成开始动画
        },
        transitionStart: () => {
          // console.log()
          if (
            this.initialSlide >= this.mySwiper.activeIndex &&
            this.initialSlide == 5
          ) {
            this.mySwiper.slideTo(4, 500, false);
          } else if (
            this.mySwiper.activeIndex == 5 &&
            this.initialSlide <= this.mySwiper.activeIndex
          ) {
            this.mySwiper.setTranslate(
              -(this.slideHeight * 4 + this.footerHeight)
            );
          }
        },
        transitionEnd: () => {
          this.initialSlide = this.mySwiper.activeIndex;
        },
        slideChange: function () {
          // if (this.activeIndex !== 5) {
          swiperAni.swiperAnimate(this);
          // }
        },
      },
    });
  },
  methods: {
    goProduct() {
      this.$router.push("/bzt");
    },
    goServe() {
      this.$router.push("/service");
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1540px) {
  .header {
    h2 {
      font-size: 40px !important;
      margin-top: 100px !important;
    }
  }
  .bgc1 {
    .intro {
      margin-top: 223px !important;
      margin-left: 92px !important;
      p {
        font-size: 22px !important;
        margin-top: 26px !important;
      }
      .color {
        margin-top: 26px !important;
        font-size: 28px !important;
      }
      .btn {
        margin-top: 30px !important;
        font-size: 18px !important;
      }
    }
    .mouse-box {
      bottom: 50px !important;
      .mouse {
        img {
          width: 30px;
        }
      }
    }
  }
  .bgc2 {
    .intro {
      margin-top: 10% !important;
      h2 {
        font-size: 40px !important;
      }
      p {
        font-size: 16px !important;
        line-height: 40px !important;
      }
      .btn {
        font-size: 16px !important;
        margin-left: 50% !important;
      }
    }
  }
  .bgc3 {
    .intro {
      margin-left: 3% !important;
      margin-top: 3% !important;
      .intro-one {
        .tit {
          font-size: 20px !important;
          line-height: 24px !important;
        }
        .info {
          font-size: 14px !important;
          line-height: 24px !important;
        }
      }
    }
  }
  .bgc4 {
    .left {
      img {
        width: 478px !important;
        height: 478px !important;
      }
    }
    .right {
        // margin-left: 40px;
        .one{
            margin-top: 80px !important;
            margin-left: 150px;
            width: 450px !important;
        }
        .two{
            margin-top: 40px !important;
            margin-left: 100px;
            width: 500px !important;
        }
        .three{
            margin-top: 40px !important;
            margin-left: 50px;
            width: 550px !important;
        }
        .four{
            margin-top: 40px !important;
            width: 600px !important;
        }
      .intro-one {
        height: 50px !important;
        p {
          margin-left: 40px !important;
        //   height: 40px !important;
          font-size: 26px !important;
        //   font-weight: bold;
          color: #ffffff; /* 将文字颜色设置为白色 */
          line-height: 50px !important;
          font-size: 20px !important;
        }
      }
    }
  }
//   .bgc4 {
    // ul {
    //   margin-top: 80px !important;
    //   li {
    //     padding: 80px 17px 40px !important;
    //     img {
    //       width: 80px !important;
    //     }
    //     p {
    //       width: 200px !important;
    //       font-size: 16px !important;
    //     }
    //   }
    // }
//   }
  .bgc5 {
    .carousel {
      margin-top: 7% !important;
    }
  }
}

.swiper-container {
  height: 100%;
  .swiper-slide {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
  }
  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    h2 {
      font-size: 60px;
      margin-top: 140px;
      margin-bottom: 12px;
    }
    .line {
      display: block;
      width: 573px;
      height: 0px;
      border: 2px solid;
      border-image: linear-gradient(
          270deg,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0),
          rgba(1, 180, 255, 1),
          rgba(1, 176, 255, 1),
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0)
        )
        4 4;
    }
  }

  .bgc1 {
    background-image: url("../../assets/home-bgc-1.png");
    .intro {
      color: #fff;
      margin-top: 15%;
      margin-left: 192px;
      p {
        position: relative;
        width: 50%;
        font-size: 30px;
        margin-top: 46px;
        line-height: 45px;
      }
      .color {
        color: #55e7ff;
        background: linear-gradient(
          to right,
          rgb(0, 255, 115),
          rgb(0, 217, 255),
          rgb(43, 255, 0)
        );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .ani {
        font-size: 42px;
      }

      .btn {
        display: inline-block;
        margin-top: 60px;
        font-size: 26px;
        border: 1px solid #fff;
        color: #fff;
        border-radius: 6px;
        padding: 6px 22px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
          transform: scale(1.01);
          background-color: #55e7ff;
          color: #fff;
          border: #55e7ff;
        }
      }
    }
    .mouse-box {
      position: absolute;
      bottom: 80px;
      z-index: 99;
      width: 100%;
      display: flex;
      justify-content: space-around;
      .mouse {
        display: flex;
        flex-direction: column;
        position: relative;
        animation: mouse 3s infinite;
      }
      @keyframes mouse {
        0% {
          top: 0px;
        }
        50% {
          top: 40px;
        }
        100% {
          top: 0px;
        }
      }
      // img {
      //     display: block;
      //     width: 38px;
      // }
      // display: flex;
      // flex-direction: column;
    }
  }
  .bgc2 {
    background-image: url("../../assets/home-bgc-2.png");
    .intro {
      color: #fff;
      width: 30%;
      margin-left: 60%;
      margin-top: 12%;
      position: relative;
      right: 0%;
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: right 0.8s ease-in-out;
      h2 {
        margin-bottom: 20px;
        font-size: 55px;
      }
      img {
        margin-bottom: 45px;
      }
      p {
        font-size: 22px;
        line-height: 70px;
      }
      .btn {
        margin-left: 60%;
        display: inline-block;
        margin-top: 30px;
        font-size: 24px;
        border: 1px solid #fff;
        color: #fff;
        border-radius: 6px;
        padding: 6px 22px;
        cursor: pointer;
        transition: #55e7ff 0.3s ease;
        &:hover {
          opacity: 0.8;
          transform: scale(1.01);
          background-color: #55e7ff;
          color: #fff;
          border: 1px solid #55e7ff;
        }
      }
    }
  }
  .bgc3 {
    background-image: url("../../assets/home-bgc-3.png");
    color: #fff;

    .intro {
      width: 36%;
      margin-left: 4%;
      margin-top: 4%;
      .intro-one {
        margin-bottom: 10px;
        border-left: 4px solid;
        border-image: linear-gradient(to bottom, #61a0ef, #3f4ed2) 4;
        cursor: pointer;

        .tit {
          padding: 12px 34px;
          font-size: 30px;
          line-height: 50px;
          border-bottom: 1px solid #3759bc;
          background-size: contain;
          background-position: 30% 30%;
          position: relative;
          overflow: hidden;
          span {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: -100%;
            z-index: -1;
            background-image: linear-gradient(
              to right,
              rgba(85, 231, 255, 1),
              rgba(85, 231, 255, 0.1)
            );
            animation: bganimation1 0.4s linear;
          }
          &:hover {
            color: #fff;
            span {
              animation: bganimation 0.4s linear;
              animation-fill-mode: forwards;
            }
          }
          @keyframes bganimation {
            0% {
              left: -100%;
            }
            100% {
              left: 0%;
            }
          }
          @keyframes bganimation1 {
            0% {
              left: 0%;
            }
            100% {
              left: -100%;
            }
          }
        }
        .info {
          font-size: 16px;
          line-height: 30px;
          padding: 12px 34px;
        }
      }
    }
  }
  .bgc4 {
    background-image: url("../../assets/home-bgc-4.png");
    color: #fff;
    .left {
      width: 45%;
      img {
        margin-top: 0px;
        margin-left: 100px;
        width: 678px;
        height: 678px;
      }
    }
    .right {
        margin-left: 40px;
        .one{
            margin-top: 120px;
            margin-left: 150px;
            width: 650px;
        }
        .two{
            margin-top: 60px;
            margin-left: 100px;
            width: 700px;
        }
        .three{
            margin-top: 60px;
            margin-left: 50px;
            width: 750px;
        }
        .four{
            margin-top: 60px;
            width: 800px;
        }
      .intro-one {
        height: 70px;
        background: linear-gradient(
          180deg,
          rgba(62, 76, 210, 0.15) 0%,
          rgba(47, 122, 203, 0.28) 100%
        );
        // border-top: 1px solid #3759bc;
        // border-bottom: 2px solid;
        // opacity: 0.7;
        border-left: 4px solid;
        border-image: linear-gradient(to bottom, #61a0ef, #3f4ed2) 4;
        p {
          margin-left: 40px;
          height: 70px;
          font-size: 30px;
        //   font-weight: bold;
          color: #ffffff; /* 将文字颜色设置为白色 */
          line-height: 70px;
          font-size: 30px;
        }
      }
    }
    // ul {
    //     display: flex;
    //     justify-content: space-around;
    //     margin-top: 80px;
    //     li {
    //         border-radius: 4px;
    //         border: 1px solid #098af4;
    //         padding: 100px 50px 100px;
    //         font-size: 30px;
    //         display: flex;
    //         align-items: center;
    //         flex-direction: column;
    //         background-image: linear-gradient(
    //             to bottom,
    //             #1e376f,
    //             #102467,
    //             #1e376f
    //         );
    //         &:hover {
    //             img {
    //                 transform: rotateY(180deg);
    //                 transition: all 0.4s ease-in-out;
    //             }
    //         }
    //         .img {
    //             margin-bottom: 60px;
    //             img {
    //                 width: 120px;
    //                 transition: all 0.4s ease-in-out;
    //             }
    //         }
    //         p {
    //             width: 220px;
    //             text-align: center;
    //         }
    //     }
    // }
  }
  .bgc5 {
    background-image: url("../../assets/home-bgc-5.png");
    color: #fff;
    // padding: 0 10%;
    .carousel {
      padding: 0 10%;
      margin-top: 8%;
    }
    .el-carousel__item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      > div {
        width: 23%;
        height: 100px;
        // background-color: #fff;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>