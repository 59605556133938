import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'
import Layout from '@/layout/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: '/',
        children: [
            {
                path: '/',
                name: 'home',
                component: Home
            },
            // {
            //     path: '/product',
            //     name: 'product',
            //     component: Layout,
            //     redirect: '/product/bzt',
            //     children: [
            {
                path: '/bzt',
                name: 'bzt',
                component: () => import('@/views/product/bzt.vue'),
            },
            {
                path: '/bzq',
                name: 'bzq',
                component: () => import('@/views/product/bzq.vue'),
            },
            {
                path: '/bzj',
                name: 'bzj',
                component: () => import('@/views/product/bzj.vue'),
            },
            //     ]
            // },
            {
                path: '/service',
                name: 'service',
                component: () => import('@/views/service/index.vue'),
            },
            {
                path: '/lab',
                name: 'lab',
                component: () => import('@/views/lab/index.vue'),
            },
            {
                path: '/about',
                name: 'about',
                component: () => import('@/views/about/index.vue'),
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    next()
})
export default router
